import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueEllipseProgress from 'vue-ellipse-progress';
import Main from "@/components/Main";


Vue.use(VueEllipseProgress);
Vue.use(VueRouter);
let router;

router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/room/:id',
      name: 'Home',
      component: App,
      children: [
        {
          path: '/cas/:id',
          name: 'casChat',
          component: Main
        },
        {
          path: '/anon/:id',
          name: 'anonChat',
          component: Main
        },
        {
          path: '/user/:id',
          name: 'userChat',
          component: Main
        },
        {
          path: '/admin/:id',
          name: 'adminChat',
          component: Main
        },
      ],
    },
  ]
});

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
