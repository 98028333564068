<template>
  <div class="card-body" id="chatCorp" style="padding: 0em; height: 64%;">
    <vue-custom-scrollbar id="scrollBar" class="scroll-area" :settings="settings" style="max-height: 100%">
    <div class="messages" style="height: 100%">
      <div class="container-fluid h-100">
        <div class="row justify-content-center h-100">
          <div class="col-md-8 col-xl-12 chat" style="flex: 1; max-width: 100%">
            <div class="card-body msg_card_body" style="padding: 0em">
              <div v-for="message in messages" v-bind:key="message.id">
                <div v-if="message.message !== '[INFO_CONNECT]'">
                  <div v-if="checkUserMsg(message.userId, message.user) === false" class="d-flex justify-content-start mb-4" style="margin-bottom: 0.8rem!important;">
                    <div class="msg_container" style="max-width: 50%">
                      <div style="border: 2px solid #178F96; border-radius: 25px; padding: 14px; text-align: left">
                        {{message.message}}
                      </div>
                      <span class="msg_time"><span v-if="isAdmin" @click="delMsg(message.id)" style="color: red; cursor: pointer"><strong>&#10060;</strong></span><b>{{message.user}}</b>, {{ message.date }}</span>
                    </div>
                  </div>
                  <div v-if="checkUserMsg(message.userId, message.user) === true" class="d-flex justify-content-end mb-4" style="margin-bottom: 0.8rem!important;">
                    <div class="msg_container_send" style="max-width: 50%">
                      <div style="border: 1px solid #178F96; border-radius: 25px; padding: 14px; background-color: #178F96; color: white; text-align: left">
                        {{message.message}}
                      </div>
                      <span class="msg_time_send" style="float: right"><b>Moi</b>, {{ message.date }}<span v-if="isAdmin" @click="delMsg(message.id)" style="color: red; cursor: pointer"><strong>&#10060;</strong></span></span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="connect_box" style="">
                    <i><b>{{message.user}}</b> s'est connecté à la discussion</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  components: {
    vueCustomScrollbar
  },
  name: "msgList",
  props: {
    messages: Array,
    user: Array,
    userName: String,
    isAdmin: Boolean,
    socket: Object,
    cas: Boolean,
  },
  data() {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      }
    }
  },
  methods: {
    delMsg(id) {
      this.socket.emit('DEL_MESSAGE', {
        idMsg: id
      });
    },
    checkUserMsg(userId, userName) {
      if(this.cas === true) {
        return this.userName === userName
      }else {
        return this.user.includes(userId)
      }
    }
  }
}
</script>

<style scoped>
.connect_box {
  border-bottom: 1px solid #178F96;
  padding: 1px;
  text-align: left;
  width: 50%;
  margin-bottom: 10px;
  font-size: 0.8rem;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 25%;
  min-width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>