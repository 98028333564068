<template>
  <div style="height: 100%;margin-top: -16px;margin-left: -15px;margin-right: -15px;">
    <modal v-if="showModal" @close="showModal = false" @click="this.startSocket" v-model="user"></modal>

    <div class="card mt-3" style="height: 100%">
      <div class="card-body" style="margin-bottom: -2rem">
        <div class="card-title">
          <div style="max-width: 100%; min-height: 10px">
            <span v-if="isAdmin" @click="downloadChat" style="cursor: pointer">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
              </svg>
            </span>
            <span>{{event}}</span>
            <span style="float: right" title="Nombre d'utilisateurs en ligne">&#128100; : {{ nbco }}</span>
          </div>
          <hr>
        </div>
      </div>
      <msg-list :user="userId" :messages="messages" :isAdmin="isAdmin" :socket="socket" :cas="cas" :user-name="user"></msg-list>
      <div class="card-footer">
        <form @submit.prevent="sendMessage">
          <div class="input-group">
            <vue-ellipse-progress :progress="message.length*100/240" color="#178F96" emptyColor="#D7B797" thickness="5" :legend-value="message.length"  animation="default 0 0" :size=50 style="margin-right: 20px">
              <template v-slot:legend-value>
                <span slot="legend-value" style="font-size: 0.5em"></span>
              </template></vue-ellipse-progress>
              <input type="text" id="message" ref="message" v-model="message" class="form-control" :placeholder="blockMsg" maxlength="240" style="margin-right: 20px; margin-top: 5px">

            <span class="input-group-btn" style="margin-top: 5px">
              <button type="submit" class="btn btn-primary">&#10148;</button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';
import modal from './modal.vue';
import Modal from "@/components/modal";
import MsgList from "@/components/msgList";
import axios from 'axios';
import md5 from 'md5';
import moment from 'moment';

export default {
  name: "Chat",
  components: {MsgList, Modal},
  comments: {
    modal,
  },
  data() {
    return {
      user: null,
      cas: false,
      userId: [],
      message: '',
      messages: [],
      event: '',
      nbco: '0',
      socket: io(process.env.VUE_APP_SERVER_URL, {
        autoConnect: true,
        transports: ['websocket'],
      }),
      showModal: false,
      date: '',
      room: '',
      blockMsg: '',
      isAdmin: false,
      progress: 0,
      alert: '',
      idEvent :'',
      roomId: null,
    }
  },
  created: function () {
    this.room = this.$route.params.id;
    var pathArray = window.location.pathname.split('/');
    if(pathArray.find(element => element === 'admin') !== undefined) {
      this.isAdmin = true;
    }
  },
  methods: {
    sendMessage(e) {
      e.preventDefault();
      if(this.message !== '') {
        this.socket.emit('SEND_MESSAGE', {
          user: this.user,
          message: this.message,
          room : this.room,
          event: this.idEvent
        });
      }
      this.message = ''
    },
    tellSocketJoin() {
      this.socket.open();
      this.socket.emit('JOIN_ROOM', {
        user: this.user,
        room: this.room,
        event: this.idEvent,
      });
      this.$nextTick(() => this.$refs.message.focus());
    },
    existRoom() {
      axios.get('https://webtv.uca.fr/assistant-podcast/api/rooms/'+this.room, {
        headers: {
          'X-AUTH-TOKEN': md5(process.env.VUE_APP_API_TOKEN + moment().format('MM-DD'))
        },
      }).then(response => {
        this.roomId = response.data.idADE
        this.joinRoom();
      });
    },
    joinRoom() {
      axios
          .get("https://webtv.uca.fr/assistant-podcast/api/record/room/"+this.roomId+"/today", {
            headers: {
              'X-AUTH-TOKEN': md5(process.env.VUE_APP_API_TOKEN + moment().format('MM-DD'))
            },
          })
          .then(response => {
            response.data.forEach(element => {
              const dateNow = moment().format();
              if(dateNow > element.startDate && dateNow < element.endDate) {
                this.idEvent = element.id;
                this.event = element.title;
              }
            });
            this.tellSocketJoin();
          })
    },
    downloadChat() {
      let csvContent = "data:text/csv;charset=utf-8,";
      let dataString = 'ID' + ';' + 'Nom d\'utilisateur' + ';' + 'Message' + ';' + 'Date' + '\n';
      csvContent += dataString;
      this.messages.forEach(function(infoArray){
        let dataString = infoArray.userId + ';' + infoArray.user + ';' + infoArray.message + ';' + infoArray.date + '\n';
        csvContent += dataString;
      });
      let encodedUri = encodeURI(csvContent);
      let fixedEncodedURI = encodedUri.replaceAll('#', '%23');
      window.open(fixedEncodedURI);
    },
    startSocket() {
      this.socket.open();
      this.existRoom();
    }
  },
  mounted() {
    if(this.isAdmin ||  this.$route.name === 'casChat')
    {
      this.cas = true;
      this.showModal= true;
    }
    if(this.$route.name === 'userChat') {
      this.showModal= true;
    }
    if(this.$route.name === 'anonChat') {
      this.startSocket();
    }

    this.socket.on('MESSAGE', (data) => {
        this.messages = [...this.messages, data];
        let container = this.$el.querySelector("#scrollBar");
        this.$nextTick(() => {
          container.scrollTop = container.scrollHeight;
        });
    });

    this.socket.on('DELETE', (data) => {
      let findMsg = this.messages.findIndex(element => element.id === data.idMsg);
      if(findMsg >= 0) {
        this.messages.splice(findMsg, 1);
      }
    });

    this.socket.on('connections', (data) => {
      this.nbco = data;
    });

    this.socket.on('FLOOD', (data) => {
      if(this.userId.includes(data.userId)) {
        document.getElementById('message').disabled = true;
        this.blockMsg = 'Envoi de message bloqué : 9';
        let that = this;
        let counter = 8
        let interval = setInterval(function () {
          that.blockMsg = 'Envoi de message bloqué : '+counter;
          counter--;
          if(counter < 0) {
            clearInterval(interval);
            document.getElementById('message').disabled = false;
            that.blockMsg = '';
          }
        }, 1000);
      }
    });

    this.socket.on('SET_ID', (data) => {
      this.userId.push(data);
    });
    this.socket.on('SET_ID_BACKUP', (data) => {
      this.userId.push(data);
    });
    this.socket.on('ARCHIVE_MSG', (data) => {
      this.messages = data;
    });
    this.socket.on('USERNAME', (data) => {
      this.user = data;
    });
  }
}
</script>

<style scoped>
  .msg_time {
    font-size: 0.9em;
  }
</style>
