<template>
  <div id='main' class="fullSize">
    <div class="container fullSize">
      <div class="row fullSize">
        <div class="col-md-10 fullSize">
          <Chat />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chat from './Chat.vue'

export default {
  name: 'Main',
  components: {
    Chat
  },
}
</script>

<style>
</style>
