<template>
  <div class="fullSize">
    <router-view :key="routeName">
    </router-view>
  </div>

</template>

<script>
import md5 from "md5";
import moment from "moment";
import axios from 'axios';

export default {
  name: 'app',
  data() {
    return {
      room: '',
      routeName: '',
    }
  },
  created: function () {
    this.routeName = this.$route.name;
    this.room = this.$route.params.id;
    this.getRoom();
  },
  methods: {
    getRoom() {
      axios.get("https://webtv.uca.fr/assistant-podcast/api/rooms", {
        headers: {
          'X-AUTH-TOKEN': md5(process.env.VUE_APP_API_TOKEN + moment().format('MM-DD'))
        },
      })
        .then(response => {
          let that = this;
          response.data.forEach(function (element) {
            if(element.agentId === that.room) {
              localStorage.roomId = element.id;
              axios.get("https://webtv.uca.fr/assistant-podcast/api/record/room/" + element.idADE + '/today', {
                headers: {
                  'X-AUTH-TOKEN': md5(process.env.VUE_APP_API_TOKEN + moment().format('MM-DD'))
                },
              }).then(response => {
                response.data.forEach(function (element2) {
                  const dateNow = moment().format();
                  if (dateNow > element2.startDate && dateNow < element2.endDate) {
                    console.log(element2.isPrivate );
                    if (element2.isPrivate === 1 && that.$route.name !== 'adminChat') {
                      that.$router.push('/cas/'+that.room).catch(()=>{});
                      that.routeName='casChat';
                    } else if(element2.isPrivate === 2 && that.$route.name !== 'adminChat') {
                      that.$router.push('/user/'+that.room).catch(()=>{});
                      that.routeName='userChat';
                    } else {
                      if(that.$route.name !== 'adminChat') {
                        that.$router.push('/anon/'+that.room).catch(()=>{});
                        that.routeName='anonChat';
                      }
                    }
                  }
                })
              });
            }
          });
        });
    }
  }
}
</script>

<style>

.fullSize {
  height: 100%;
}

body {
  height: 100%;
}
</style>
