<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header" style="display: block">
            <slot name="header">
              <img style="display: inline-block" src="https://dsi.uca.fr/uas/dsi/LOGO_PIED/logo_footer.png">
              <h3 style="color: #178F96; display: inline-block; margin-left: 15%">{{title}}</h3>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div class="alert alert-dismissible alert-danger" id="warning" style="display: none; font-size: 13px; padding-left: 12px">
                Le nom d'utilisateur ou le mot de passe est incorrect !
              </div>
              <form @submit.prevent="setUsername">
                <label for="username" style="width: 100%">Nom d'utilisateur
                  <input type="text" ref='username' id="username" v-model="userTmp" class="form-control">
                </label>
                <label for="password" style="width: 100%" v-if="isCas">Mot de passe
                  <input type="password" ref='password' id="password" v-model="pwd" class="form-control">
                </label>
                <input type="submit" style="display: none" />
              </form>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn btn-primary" @click="setUsername">
                Se connecter
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
export default {
  name: "modal",
  data() {
    return {
      userTmp: '',
      pwd: '',
      title: 'Je m\'identifie',
      isCas: true,
    }
  },
  props: {
    user: String
  },
  created() {
    this.$nextTick(() => this.$refs.username.focus());
  },
  mounted() {
    if(this.$route.name === 'userChat') {
      this.isCas = false;
    }
  },
  methods: {
    setUsername() {
      document.getElementById("warning").style.display = "none";
      if(this.$route.name === 'userChat') {
        this.$emit('close');
        this.$emit('input', this.userTmp);
        this.$emit('click');
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append('login', this.userTmp);
        bodyFormData.append('password', this.pwd);
        bodyFormData.append('service', 'connexion');
        axios({
          method: "POST",
          url: "https://mobile.dsi.uca.fr",
          data: bodyFormData,
        }).then(response => {
          this.$emit('close');
          this.$emit('input', response.data.givenName);
          this.$emit('click');
        }).catch(function (error) {
          document.getElementById("username").style.borderColor = "red";
          document.getElementById("password").style.borderColor = "red";
          document.getElementById("warning").style.display = "block";
          console.log(error);
        });
      }
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 25%;
  min-width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>